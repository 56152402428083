import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faApple,
  faSpotify,
  faYoutube,
  faBandcamp,
} from "@fortawesome/free-brands-svg-icons"
import { faWaveform } from "@fortawesome/pro-solid-svg-icons"

const BrandButton = ({ brand, href, label, size, outline }) => {
  const brands = {
    apple: {
      label: "Apple Music",
      icon: faApple,
      colour: "apple",
    },
    spotify: {
      label: "Spotify",
      icon: faSpotify,
      colour: "spotify",
    },
    youtubemusic: {
      label: "YouTube Music",
      icon: faYoutube,
      colour: "youtube",
    },
    bandcamp: {
      label: "Bandcamp",
      icon: faBandcamp,
      colour: "bandcamp",
    },
    tidal: {
      label: "Tidal",
      icon: faWaveform,
      colour: "tidal",
    },
  }
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer noopener"
      class={`btn btn${(outline !== "false" && `-outline`) || ``}-${
        brands[`${brand}`].colour
      } mb-3 mx-2 ${size && `btn-${size}`}`}
    >
      <FontAwesomeIcon icon={brands[`${brand}`].icon} className="mr-2" />
      {label || `${brands[`${brand}`].label}`}
    </a>
  )
}

export default BrandButton
